.site-header {
  .site-utils__item {
    .cart_overlay {
      .cart-product-preorder {
        float: left;
        font-size: 12px;
      }
    }
  }
}

.mpp,
.spp,
.spp-mobile-page {
  .product__flag {
    display: inline-block;
  }
}

.mpp {
  @include breakpoint($medium-down) {
    .product__flag_preorder {
      margin-bottom: 5px;
    }
  }
}

.spp {
  .product {
    .product__title {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

.site-header {
  &__addtobag {
    .product__inventory-status {
      .preorder-noshop {
        display: none !important;
      }
    }
  }
}

.pre_order_temp_out_of_stock {
  .temp_out_of_stock_msg {
    display: none;
  }
}

.peace_love_beach {
  .quickshop-wrapper {
    .button {
      float: none;
    }
  }
}

.product {
  &__inventory-status {
    .preorder-noshop {
      font-size: 11px;
      text-align: left;
      .spp-mobile-page & {
        text-align: center;
      }
      @include breakpoint($medium-up) {
        width: 170px;
        float: right;
        font-size: 12px;
        text-align: right;
      }
    }
  }
  &__notify-me {
    @include breakpoint($medium-down) {
      .button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.confirmation-panel {
  &__content {
    .pre-order-message-display {
      font-size: 14px;
    }
  }
}

#results-container {
  .product__flag {
    display: inline-block;
  }
}

.favorites-page,
.past-purchases {
  &__content {
    .pre_order_message_detail {
      font-size: 12px;
    }
  }
}

.spp-mobile-page {
  .shaded_details {
    .product__inventory-status {
      .preorder-noshop {
        text-align: left;
      }
    }
  }
}

.customer-service {
  .customer-service-menu {
    .menu__link {
      height: auto;
    }
  }
}

.section-contact-us {
  .customer-service-section {
    .multiple-forms {
      margin: 0 auto;
      header {
        padding: 0.5em 20px 1.5em 20px;
        h2 {
          border-top: 0;
          background: none;
          cursor: default;
          &:hover {
            color: $color-black;
          }
        }
      }
      section {
        ul {
          &.tabs {
            & > li {
              width: 44%;
              @include breakpoint($medium-up) {
                width: 23%;
                display: block;
                margin: 0 2% 0 0;
              }
            }
            a {
              padding: 1em 0px;
              @media (max-width: 767px) {
                padding: 1em 0.25em;
                letter-spacing: 3px;
              }
              @media only screen and (max-device-width: 320px) {
                padding: 0.5em 0px;
              }
              @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                padding: 0.25em 0px;
              }
            }
          }
        }
      }
      header,
      section {
        width: 100%;
      }
    }
  }
}

.section-thanks-contact-us {
  .customer-service-section {
    margin: 0 auto;
    header {
      padding: 0.5em 20px 1.5em 20px;
      margin: 0 auto;
      border-bottom: 2px solid $color-black;
      @include breakpoint($medium-down) {
        text-align: center;
        border-bottom: 1px solid $color-gray-light-alt;
      }
      h1 {
        border-top: 0;
        background: none;
        cursor: default;
        margin: 0px;
        letter-spacing: 5px;
        font-size: 1.5em;
        text-transform: uppercase;
        padding: 0px;
        font-family: $brandon-text-bold;
        font-weight: bold;
        border: none;
        &:hover {
          color: $color-black;
        }
      }
    }
    section {
      &.contact-thanks-section {
        #contact-thanks-about {
          padding-bottom: 0px;
          background: none;
        }
        .contact-thanks-content {
          border-bottom: 1px solid $color-gray-light-alt;
          margin: 0px;
          padding: 1.5em 20px;
          border-top: 1px solid $color-gray-light-alt;
          @include breakpoint($medium-down) {
            border-top: none;
          }
          p::first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
    header,
    section {
      width: 100%;
    }
  }
}

.address-book-page {
  .address-book__default {
    @include breakpoint($medium-up) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .address-item {
    @include breakpoint($medium-up) {
      float: none;
    }
  }
  .address-item__address {
    @include breakpoint($medium-up) {
      margin-bottom: 0;
      height: 100%;
    }
  }
}
