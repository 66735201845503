h2.viewcart-panel__title .checkout-header__title__count {
  display: inline-block !important;
}

.offer-code-panel {
  .offer-code-panel__title {
    .expando-block__icon {
      display: none;
    }
  }
}

.offer-code__one-offer-only {
  a.continue-shopping {
    border: none;
  }
}

.offer-code__one-offer-only-body {
  margin: 50px 0 30px;
  @include breakpoint($large-up) {
    margin: 15px 0;
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        .prod-info {
          @include breakpoint($small-down) {
            width: 45%;
          }
          a {
            @include breakpoint($small-down) {
              letter-spacing: 2px;
            }
          }
        }
        .price,
        .prod-info .cart-product-name_header {
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          @-moz-document url-prefix() {
            font-weight: normal;
          }
        }
        .price {
          @include breakpoint($small-down) {
            width: 23%;
            letter-spacing: 2px;
          }
        }
      }
    }
    .checkout {
      padding: 20px;
    }
    .promos {
      .promo {
        .free_ship {
          display: none;
        }
      }
    }
  }
}

.checkout {
  .links_list .link {
    @include breakpoint($small-down) {
      border: none;
    }
  }
  .cart-item__product-name {
    @include breakpoint($small-down) {
      padding-right: 25px;
    }
  }
  .remove_link {
    @include breakpoint($small-down) {
      font-size: 25px;
      border: 0;
      position: absolute;
      top: 0;
      right: 10px;
    }
  }
  .links-panel {
    margin-top: 10px;
    @include breakpoint($small-down) {
      margin-#{$ldirection}: $mobile-outer-gutter;
      margin-#{$rdirection}: $mobile-outer-gutter;
    }
  }
  .addresses {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .field-container {
      width: 48%;
      .address1 {
        input {
          border-color: $color-black;
          &:focus {
            border-color: $color-pink;
          }
        }
      }
    }
    .address2,
    .first-name {
      width: 48%;
    }
    .last-name {
      width: 48%;
      &:last-child {
        width: 100%;
      }
    }
  }
  .new-address {
    .city-state-zip {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .postal-code {
        width: 48%;
        input {
          border-color: $color-black;
          &:focus {
            border-color: $color-pink;
          }
        }
      }
      .last-name {
        order: 2;
        width: 48%;
      }
      .first-name {
        order: 4;
        width: 48%;
        select#form--address_shipping--field--COLONY,
        .selectBox {
          border-color: $color-black;
        }
      }
      .state,
      .city {
        width: 48%;
      }
      .form-item {
        margin-#{$ldirection}: 0;
        @include breakpoint($small-down) {
          float: none;
        }
      }
    }
  }
  .address-form {
    .name-fields {
      flex-flow: row wrap;
      justify-content: space-between;
      .first-name {
        &:last-child {
          width: 100%;
        }
        input {
          border-color: $color-black;
          &:focus {
            border-color: $color-pink;
          }
        }
      }
      .last-name {
        input {
          border-color: $color-black;
          &:focus {
            border-color: $color-pink;
          }
        }
      }
    }
  }
  /* @todo fix misspelling of payment-edi-content__title.  This is in the markup as well but this close to launch we decided to leave it and refactor later in order not to accidentally break something else this close to launch*/
  .payment-panel {
    .payment-edit-content {
      .payment-edi-content__title {
        @include breakpoint($small-down) {
          padding: 0 20px;
        }
      }
      .payment_form {
        .payment-type {
          .payment-option {
            @include breakpoint($small-down) {
              padding-left: 20px;
            }
          }
        }
        #paypal-info {
          @include breakpoint($small-down) {
            margin-left: 1.5em;
          }
        }
      }
      .payment-container {
        @include breakpoint($small-down) {
          float: none;
          border-bottom: none;
        }
      }
    }
  }
  .viewcart-buttons-panel {
    .continue-buttons {
      .choose-samples-and {
        display: none;
      }
    }
  }
}

.payment-panel .payment-form {
  .form-item {
    position: initial;
    .expires-date__month,
    .expires-date__year {
      @include breakpoint($large-up) {
        overflow: visible;
        width: 160px;
      }
      label {
        line-height: 42px;
      }
    }
    .expires-date__year {
      @include breakpoint($large-up) {
        margin-left: 20px;
      }
    }
    .expires-date__container {
      width: 100%;
      @include breakpoint($large-up) {
        padding-bottom: 1px;
        overflow: visible;
        margin-bottom: 1em;
      }
    }
    &.cvv {
      float: none;
      width: 100%;
      margin-left: 0;
    }
  }
}
.opc__payment .expires-month {
  width: 102.66%;
}

.viewcart {
  .checkout__sidebar {
    .checkout_sticky {
      height: 5px;
    }
  }
}
