body {
  .spp {
    .product {
      .product__title {
        .product__flag {
          display: none;
        }
      }
    }
  }
  .mpp {
    .product-grid__item,
    .quickshop {
      .product__flag {
        display: none;
      }
    }
    .quickshop {
      .product {
        &__unit-price {
          display: none;
        }
      }
    }
  }
  .mpp-filter-set {
    display: none;
  }
}

.spp .product {
  &__details {
    .desktop-block.product__tip {
      visibility: hidden;
      padding-bottom: 60px;
      .product__tip-title {
        margin-bottom: 0px;
      }
    }
  }
}

.spp .responsive-tabs--how_to_use-wrap {
  border-bottom: none;
}

h2.product-full-details__data-heading-label {
  margin: 0;
}
