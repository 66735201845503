.product__favorite {
  display: none;
}

body {
  .sign-in-page {
    .account__new-account {
      .new-account__fieldset {
        .optional-info {
          &__item {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            &:before {
              content: none;
            }
          }
          &__birthday-day,
          &__birthday-month {
            width: 48%;
            margin: 0;
            select {
              margin: 0;
            }
          }
          &__birthday-year {
            width: 48%;
            margin-top: 12px;
            select {
              background-position: 95% 50%;
              width: 100%;
            }
          }
        }
        .show_password_link_container {
          text-align: end;
          .signin_show_password_link {
            left: unset;
            margin-inline-end: 10px;
          }
        }
      }
    }
  }
  header.site-header {
    .site-utils {
      &__promo {
        line-height: 28px;
      }
      &--sticky {
        @include breakpoint($medium-portrait-only) {
          .site-utils__search {
            left: 10px;
          }
          .site-header__menu-list {
            position: relative;
            left: 26px;
          }
          .site-utils__links {
            .site-utils__right {
              right: 20px;
            }
          }
        }
      }
    }
  }
  .tabbed-products-formatter {
    @include breakpoint($small-down) {
      padding: 0 15px;
    }
  }
  &.section-customer-service {
    .customer-service-section {
      #landing-call {
        border-bottom: 1px solid $color-gray-alt;
      }
      #landing-email {
        background: none;
        border-top: none;
        border-bottom: 1px solid $color-gray-alt;
      }
      #landing-faqs {
        border-top: none;
        border-right: 1px solid $color-gray-alt;
      }
    }
  }
  &.section-contact-us {
    @media (max-width: 767px) {
      .multiple-forms section {
        width: 100%;
        .tabbed-forms-content li {
          margin: 1em 2%;
        }
        .forms .form-builder-container {
          .form-item {
            &.form-type-textfield,
            &.form-type-textarea,
            &.form-type-select {
              width: 100%;
            }
            &.form-type-select {
              select {
                background: url(/media/images/global/icon-dropdown.png) no-repeat center #{$rdirection}
                  5px;
              }
            }
          }
          .form-submit {
            width: 100%;
          }
        }
      }
    }
    .customer-service-contact-us .customer-service-section div {
      .grippie {
        display: none;
      }
    }
  }
  &.section-unsubscribe,
  &.section-unsubscribe-thanks-page {
    .unsubscribe,
    .unsubscribe-thanks {
      overflow: hidden;
      position: relative;
      max-width: 1024px;
      margin: 3em auto;
      padding: 0;
      header {
        border-bottom: 2px solid #000;
        margin: 0 auto;
        padding: 20px;
        width: 80%;
        .unsubscribe-title,
        .unsubscribe-thanks-title {
          margin-bottom: 0;
        }
      }
      .unsubscribe-content,
      .unsubscribe-thanks-content {
        padding: 20px 0;
        margin: 0 auto;
        width: 80%;
      }
      .unsubscribe-content {
        padding: 20px 0 0;
        p {
          margin-bottom: 10px;
          &.email-label {
            margin-bottom: 5px;
          }
        }
      }
    }
    .unsubscribe {
      margin-bottom: 0px;
    }
    .site-email-signup.email-unsubscribe {
      overflow: hidden;
      position: relative;
      max-width: 1024px;
      margin: 0 auto 3em;
      padding: 0;
      form {
        padding: 20px 0;
        margin: 0 auto;
        width: 80%;
        padding-top: 0px;
        .site-email-signup__field {
          position: relative;
          top: 2px;
        }
        .site-email-signup__submit {
          padding-top: 3px;
          padding-bottom: 3px;
          height: 32px;
          line-height: 20px;
        }
      }
    }
  }
  &.elc-checkout--review,
  &.elc-checkout--shipping,
  &.elc-checkout--payment {
    .site-footer {
      .site-footer__wrap {
        .block-template-site-footer-country-chooser-v1 {
          display: none;
        }
        @media (max-width: 767px) {
          .footer-text {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
  &.elc-checkout--order {
    .site-footer {
      .site-footer__wrap {
        .secure-pay-container {
          display: none;
        }
      }
    }
  }
  section {
    &.text-block_recommended_products {
      .text-block_title {
        text-align: center;
        font-family: $brandon-text-bold;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        text-indent: 0;
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        &:before,
        &:after {
          border-top: 2px solid #000;
          width: 5%;
          content: '\a0 ';
          position: relative;
          top: 0.8em;
          display: inline-block;
          margin: 0 0.4em 0 0.2em;
        }
      }
    }
  }
  &.elc-checkout--shipping,
  &.elc-checkout--payment {
    .site-utils__right {
      @media (max-width: 767px) {
        .site-utils__icon--hamburger {
          display: none;
        }
        .mobile-checkout-back {
          display: block;
          .icon {
            font-size: 39px;
            position: absolute;
            left: 12px;
            top: 10px;
          }
        }
      }
    }
    .site-header__nav {
      display: none;
    }
    .text-block_recommended_products {
      display: none;
    }
  }
  .elc-checkout__noderef-route {
    margin-bottom: 60px;
  }
  .homepage_basic {
    @media (max-width: 767px) {
      .homepage_basic_dek {
        font-family: $brandon-text-regular;
      }
    }
  }
  &.device-mobile {
    .customer-service-section {
      a {
        .customer-service-landing-block__link {
          bottom: 0;
        }
      }
    }
  }
}

.popup-offer__footer {
  margin-bottom: 10px;
}

input {
  #edit-submit--7 {
    display: block !important;
    visibility: visible;
  }
}

.site-utils {
  &__logo-icon {
    @include breakpoint($medium-up) {
      font-size: 19px;
    }
  }
  &__logo {
    @include breakpoint($medium-up) {
      width: 145px;
    }
  }
  &__nav {
    .menu__link--lvl-1 {
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 0 3px);
        font-size: 12px;
        letter-spacing: 0.15em;
      }
    }
  }
}

.page-header--fit-to-img {
  padding-bottom: 70px;
  .page-header__subtitle {
    padding: 20px;
  }
  .page-header__text-wrapper {
    position: absolute;
  }
}

.site-footer {
  .site-footer__main {
    .country-select {
      .country-select__selectbox {
        width: 90%;
      }
    }
  }
}

.site-header {
  .site-utils__item--email {
    .site-email-signup {
      &__field {
        text-align: center;
      }
    }
  }
  .search-form {
    background: transparent;
    input[type='text'].search-form__field {
      @include breakpoint($medium-only) {
        max-width: 120px;
      }
      @include breakpoint($xlarge-up) {
        max-width: 140px;
      }
    }
  }
  .gnav-section__link {
    @include breakpoint($medium-only) {
      font-size: 12px;
    }
    @include breakpoint($xlarge-up) {
      font-size: 15px;
    }
  }
}

.payment-panel {
  .payment-option,
  .payment-control {
    &.radio:first-child,
    &.radio:last-child {
      @include breakpoint($medium-up) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
  .payment-form__pp_cash {
    position: initial;
  }
}

.product-brief {
  &__product-info-wrapper {
    height: 100%;
  }
}

.search-form {
  &--results {
    .search-form__results-count {
      border: none;
      line-height: 2;
    }
  }
}

.content-block__inner-product {
  .content-block__product-grid {
    .product-brief__product-info-wrapper {
      @include breakpoint($medium-up) {
        .product-brief__abstract {
          height: 30%;
        }
      }
    }
  }
}

#checkout_billing {
  .payment-type {
    .select-address {
      .address-to-use {
        display: inherit;
      }
    }
  }
}
